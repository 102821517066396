import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Pagination from '../components/Pagination';
import Caption from '../components/Caption';
import Split from '../components/Split';

import styles from './chateau-margaux.module.css';

const ChateauMargauxPage = ({ data }) => {
  return (
    <Layout>
      <Section heading="Chateau Margaux">
        <p>
          In January this year Claire and Sam were fortunate to visit Chateau
          Margaux for a tour of the estate and winery. It was an invaluable
          experience to witness first hand the history and operations of this 650
          acre first growth establishment which is now nearly 1000 years old.
        </p>
        <Split reverse>
          <div className={styles.image}>
            <Img fluid={data.image.childImageSharp.fluid} />
            <Caption>Claire and Sam outside the reconstructed Chateau Margaux completed in 1812</Caption>
          </div>
          <div>
            <p>
              The recent renovations (completed in 2015) encompassed a full upgrade
              of the cellars, improved production facilities, a new grape reception
              area, upgraded laboratory facilities, a redesign of the famous bottle
              library, an onsite cooperage and new reception facilities to welcome
              visitors. Fascinatingly this was the first substantial modernisation since
              the cellars were constructed in 1810...our own cellar extension after
              45 years in operation does now not make us feel so bad!
            </p>
            <p>
              Incredibly, Chateau Margaux remains owned and operated by the
              Mentzelopoulos family (purchased by Andre Mentzelopoulis in 1976) with
              Andre’s daughter Corinne reclaiming the sole shareholding in 2003.
            </p>
            <p>
              Whilst the scale of operations at Chateau Margaux is slightly larger
              than ours at Mount Mary (a team of 80+ full time employees and
              250+ harvest employees!) we took great interest in how they have
              adapted their processes and procedures in the modern era, in particular,
              their commitment to organic farming and technological advancements in the
              vinification processes.
            </p>
          </div>
        </Split>
        <div className={styles.imageRow}>
          <div className={styles.image}>
            <Img fluid={data.image2.childImageSharp.fluid} />
            <Caption>Sam surveying the 650 acre Chateau Margaux domain</Caption>
          </div>
          <div className={styles.image}>
            <Img fluid={data.image3.childImageSharp.fluid} />
            <Caption>Fermentation room where the magic happens for 150,000 bottles of the Grand Vin each year</Caption>
          </div>
        </div>
        <div className={styles.imageRow}>
          <div className={styles.image}>
            <Img fluid={data.image5.childImageSharp.fluid} />
            <Caption>We could only imagine what this space looks like during Harvest operations with the 270 member harvest team!</Caption>
          </div>
          <div className={styles.image}>
            <Img fluid={data.image4.childImageSharp.fluid} />
            <Caption>The barrel hall</Caption>
          </div>
        </div>
        <div className={styles.imageRow}>
          <div className={styles.image}>
            <Img fluid={data.image6.childImageSharp.fluid} />
            <Caption>Tank fermentation and holding room</Caption>
          </div>
          <div className={styles.image}>
            <Img fluid={data.image7.childImageSharp.fluid} />
            <Caption>Onsite cooperage...!</Caption>
          </div>
        </div>
      </Section>
      <Pagination
        forwardLink="/team"
        backLink="/global"
      />
    </Layout>
  );
};

ChateauMargauxPage.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object,
  }),
};


export const query = graphql`
  query {
    image: file(relativePath: { eq: "Margaux2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "Margaux1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "Margaux3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "Margaux5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image5: file(relativePath: { eq: "Margaux6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image6: file(relativePath: { eq: "Margaux9.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image7: file(relativePath: { eq: "Margaux10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default ChateauMargauxPage;
